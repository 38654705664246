@tailwind base;

.text-lg .text-xl .text-2xl .text-3xl .text-4xl  {
    @apply font-display;
}

@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;1,200&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;1,200&display=swap');

@import url('https://cdn.jsdelivr.net/gh/lazywasabi/thai-web-fonts@6/fonts/NotoSansThaiLooped/NotoSansThaiLooped.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap'); 

.print-only{
    display: none;
}
@media print {

   .print-disable{
        display: none;
   }
   .print-only{
    display: block;
   }
   
  
   
  }


  .ExcelTable2007 {
	border: 1px solid #B0CBEF;
	border-width: 1px 0px 0px 1px;
	font-size: 11pt;
	font-family: Calibri;
	font-weight: 100;
	border-spacing: 0px;
	border-collapse: collapse;
	padding: 10px;
}

.ExcelTable2007 TH {
	background-repeat: repeat-x; 
	font-weight: bold;
	font-size: 14px;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	height: 17px;
	line-height: 17px;
}

.ExcelTable2007 TD {
	border: 0px;
	background-color: white;
	padding: 0px 4px 0px 2px;
	border: 1px solid #D0D7E5;
	border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
	border: 0px;
	background-color: white;
	font-weight: bold;
}

.ExcelTable2007 TD.heading {
	background-color: #E4ECF7;
	text-align: center;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	font-weight: bold;
}

.ExcelTable2007 TH.heading {
	background-repeat: none;
}